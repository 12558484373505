import "lazysizes"

import React from "react"
import PropTypes from "prop-types"

import Menu from "./menu"
import "../styles/main.scss"

const Layout = ({ children }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <>
      <div className="main-grid">
        <Menu />
        <span
          id="toTop"
          role="button"
          onKeyPress={scrollTop}
          tabIndex="-5"
          onClick={scrollTop}
        >
          <span className="material-icons">arrow_upward</span>
        </span>
        <main className="main">
          <div className="content">{children}</div>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
