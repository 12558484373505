import React from "react"
import { Link } from "gatsby"

const Menu = () => (
  <div className="menu" id="menu">
    <header>
      <div></div>
      <Link to="/">Eduardo Ceballos</Link>
      <span
        role="button"
        onClick={menuToggler}
        onKeyDown={menuToggler}
        tabIndex="0"
      >
        MENU
      </span>
    </header>
    <div>
      <header>
        <div></div>
        <Link to="/">Eduardo Ceballos</Link>
        <span
          role="button"
          onClick={menuToggler}
          onKeyPress={menuToggler}
          tabIndex="-1"
        >
          CERRAR
        </span>
      </header>
      <nav>
        <Link activeClassName="--active" to="/">
          Inicio
        </Link>
        <Link activeClassName="--active" to="/libros">
          Libros
        </Link>
        <Link activeClassName="--active" to="/revistas">
          Revistas
        </Link>
        <Link activeClassName="--active" to="/libros/efemerides">
          Efemérides
        </Link>
        <Link activeClassName="--active" to="/poemas">
          Poemas
        </Link>
        <Link activeClassName="--active" to="/reconocimientos">
          Reconocimientos
        </Link>
        <Link activeClassName="--active" to="/discografia">
          Discografía
        </Link>
        <Link activeClassName="--active" to="/la-gotita-literaria">
          La Gotita Literaria
        </Link>
        <Link activeClassName="--active" to="/fotos">
          Fotos
        </Link>
        <Link activeClassName="--active" to="/notas">
          Notas
        </Link>
        <Link activeClassName="--active" to="/videos">
          Videos
        </Link>
        <Link activeClassName="--active" to="/contacto">
          Contacto
        </Link>
        <Link activeClassName="--active" to="/tokyo">
          Universidad de Tokyo
        </Link>
        <a style={{ color: "#ececece" }} href="https://radiolagauchita.com.ar/">
          <h6>Radio La Gauchita</h6>
        </a>
      </nav>
    </div>
  </div>
)

function menuToggler() {
  document.getElementById("menu").classList.toggle("--active")
}

export default Menu
